<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Editing document properties</h3>

	<p>To edit the <span v-html="link('case_document', 'CASE document metadata')"></span> for a framework:</p>
	<ul>
		<li><span v-html="link('signing_in', 'Sign in')"></span> to <span v-html="app_name"/> an account with sufficient permissions.</li>
		<li>Enter “edit mode” by clicking the Edit <nobr>button <v-icon small>fas fa-edit</v-icon></nobr> while a framework's tree display is open. <img alt="Edit mode example image" srcset="/docimages/edit_document-1.png 3x" class="k-help-img float-right"> The edit button will turn black and have a circle to indicate editing is enabled.</li>
		<li>Click the document item at the top the framework, indicated with the map <nobr>symbol <v-icon small>fas fa-map</v-icon> </nobr></li>
		<li>In the item card that opens, click <nobr><v-icon small>fas fa-edit</v-icon> EDIT FRAMEWORK</nobr>, then choose “Edit document properties” from the drop-down menu.</li>
		<li>Refer to the help article on <span v-html="link('case_document', 'CASE document metadata')"></span> for details on the meaning of the document properties and settings. You can scroll to display additional properties in this window.</li>
		<li>When you have finished your changes, click the DONE EDITING button.</li>
	</ul>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	